const team = [
    {
      name: "",
      position: "President",
      image: "default.jpeg",
    },
    {
      name: "",
      position: "Immediate Past President",
      image: "default.jpeg",
    },
    {
      name: "",
      position: "Vice President",
      image: "default.jpeg",
    },
    {
      name: "",
      position: "Secretary",
      image: "default.jpeg",
    },
    {
      name: "",
      position: "Treasurer",
      image: "default.jpeg",
    },
    {
      name: "",
      position: "Sergeant at Arms",
      image: "default.jpeg",
    },
  ];

  export default team