function CalendarCard({ title, body, start, end, location }) {
  const formatAMPM = (date) => {
    if (date === undefined || date === null || isNaN(date)) {
      return "";
    }
    let hours = date?.getHours();
    let minutes = date?.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  var endDate = new Date(end);

  const startDate = new Date(start);

  var div = document.createElement("div");
  div.innerHTML = body;
  var text = div.textContent || div.innerText || "";
  return (
    <div className="calendar-card">
      <div className="calendar-card-body">
        <h1 className="day">{`${startDate.getDate()}`.padStart(2, "0")}</h1>
        <h4 className="month text-primary">
          {startDate.toLocaleString("default", { month: "long" }).toUpperCase()}
        </h4>
        <h2 className="mt-5">{title}</h2>
        <p>{text ?? "No description available"}</p>
        <h5>{formatAMPM(startDate)}{+ !isNaN(endDate) ? (`-${formatAMPM(endDate)}`) : ("")}</h5>
        {location && <h5 className="text-primary">@ {location}</h5>}
      </div>
    </div>
  );
}

export default CalendarCard;
