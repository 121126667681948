// import { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
// import PrimaryButton from "../../components/button";
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
// import { NotionApi } from "../../../service/api";
// import { toast } from "react-hot-toast";

import "./formmodal.css";
// import { ClipLoader } from "react-spinners";
import ShirtForm from "./forms/shirt";

function FormModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-form">
          {props.formtype === "shirts" ? <ShirtForm /> : <p>No form</p>}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FormModal;
