import "./header.css";
import { useState } from "react";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";
import FormModal from "../modal/formmodal";

function Header() {
  //
  const [modalShow, setModalShow] = useState(false);
  const [formtype, setFormtype] = useState(undefined);
  // function showClubShirtForm() {
  //   setModalShow(true);
  //   setFormtype("shirts");
  // }
  function hideForm() {
    setModalShow(false);
    setFormtype(undefined);
  }
  const navBarMenuClicked = function (e) {
    // select("#navbar").classList.toggle("navbar-mobile");
    e.target.classList.toggle("bi-list");
    e.target.classList.toggle("bi-x");
  };
  const _location = useLocation();
  const homeLocation = _location.pathname === "/" || _location.pathname === "";

  return (
    <header id="header" className="header fixed-top header-scrolled">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src="assets/img/logos/rotaract.png" alt="NKRC logo" />
        </a>
        <FormModal
          formtype={formtype}
          show={modalShow}
          onHide={() => hideForm()}
        />
        <nav id="navbar" className="navbar">
          <ul>
            {homeLocation ? (
              <li>
                <Link
                  className="nav-link"
                  to="hero"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={50}
                  delay={0}
                >
                  Home
                </Link>
              </li>
            ) : (
              <a href="/" className="nav-link">
                Home
              </a>
            )}

            {homeLocation ? (
              <>
                {/* <li>
                  <Link
                    className="nav-link"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={50}
                    delay={0}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={50}
                    delay={0}
                  >
                    Focus
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={50}
                    delay={0}
                  >
                    Team
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={50}
                    delay={0}
                  >
                    Contact
                  </Link>
                </li> */}
              </>
            ) : (
              <></>
            )}
            <li className="dropdown">
              <Link to="hero" className="nav-link">
                <span>Membership</span> <i className="ri-arrow-down-s-line"></i>
              </Link>
              <ul>
                <li>
                  <a href="/register" rel="noopener noreferrer">
                    Become a Member
                  </a>
                </li>
                <li>
                  <a
                    href="https://my-cms.rotary.org/en/document/rotaract-handbook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Handbook
                  </a>
                </li>
                <li className="dropdown">
                  <Link to="_" className="nav-link">
                    <span>Forms</span>
                    <i className="ri-arrow-right-s-line"></i>
                  </Link>
                  <ul>
                    <li>
                      <a href="docs/Leave-of-Absence.pdf">Leave of Absence</a>
                    </li>
                    {/* <li>
                      <Link
                        className="nav-link"
                        to="hero"
                        onClick={() => showClubShirtForm()}
                      >
                        Club Shirts
                      </Link>
                    </li> */}
                    <li>
                      <a href="docs/NKRC-Membership-Application-Form.docx">
                        Application Form
                      </a>
                    </li>
                    {/* <li>
                      <a href="#about">Deep Drop Down 4</a>
                    </li>
                    <li>
                      <a href="#about">Deep Drop Down 5</a>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <a href="docs/Prospective-Checklist.docx">
                    Membership Checklist
                  </a>
                </li>
                {/* <li>
                  <a href="#about">Drop Down 4</a>
                </li> */}
              </ul>
            </li>
            <li className="dropdown">
              <Link to="hero" className="nav-link">
                <span>About</span> <i className="ri-arrow-down-s-line"></i>
              </Link>
              <ul>
                <li>
                  <a href="/presidents" rel="noopener noreferrer">
                    Presidents
                  </a>
                </li>
                <li>
                  <a href="/projects">Notable Projects</a>
                </li>
                <li>
                  <a href="/events">Events</a>
                </li>
              </ul>
            </li>

            {/* <li>
              <a
                className={`nav-link${
                  _location.pathname === "/events" ? " active" : ""
                }`}
                href="/events"
              >
                Events
              </a>
            </li> */}
            <li>
              <a
                className={`nav-link${
                  _location.pathname === "/achievements" ? " active" : ""
                }`}
                href="/achievements"
              >
                Achievements
              </a>
            </li>
            {/* <li>
              <a
                className={`nav-link${
                  _location.pathname === "/register" ? " active" : ""
                }`}
                href="/register"
              >
                Become a Member
              </a>
            </li> */}
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://nkrctimes.wordpress.com/"
              >
                Our Blog
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://rotaractd7020.org/"
              >
                District 7020
              </a>
            </li>
          </ul>

          <svg
            className="mobile-nav-toggle w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            onClick={navBarMenuClicked}
          >
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </svg>
        </nav>
      </div>
    </header>
  );
}

export default Header;
