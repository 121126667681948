import { presidentData } from "./presidents";

function Presidents() {
  presidentData.reverse();
  return (
    <section className="bg-white">
      <div className="container px-6 py-10 mx-auto">
        <div className="text-center">
          <h3>NKRC Past Presidents</h3>
          <h2>Our Legacy</h2>
        </div>
        <p className="max-w-3xl mx-auto my-6 text-center text-gray-500">
          Welcome to the New Kingston Rotaract Club's Past Presidents page.
          Here, we honor the exceptional leadership of our former presidents,
          who have contributed their time, skills, and passion to the growth and
          success of our club. Each of our past presidents has made a unique
          impact on the organization, leaving behind a legacy of service and
          dedication. We take this opportunity to express our gratitude and
          appreciation for their outstanding contributions, and to highlight
          their achievements for our current and future members.
        </p>

        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4">
          {presidentData.map((president, index) => {
            return (
              <div className="flex flex-col items-center p-8 transition-colors duration-300 transform cursor-pointer group hover:bg-pink-600 rounded-xl">
                <img
                  className="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
                  src={president.photo}
                  alt={`${
                    index === 0
                      ? "President"
                      : index === 1
                      ? "Immediate Past President"
                      : "Past President"
                  } ${president.name} (${president.year})`}
                />

                <h1 className="mt-4 text-2xl font-semibold text-gray-700 capitalize group-hover:text-white">
                  {president.name}
                </h1>

                <p className="mt-2 text-gray-500 capitalize group-hover:text-gray-300">
                  {president.year}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
export default Presidents;
