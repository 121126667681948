import "./footer.css";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import PrimaryButton from "../button/button";
import { EmailApi } from "../../service/api";
import ClipLoader from "react-spinners/ClipLoader";
import { SocialMedia } from "../social";

function Footer() {
  const [loading, setLoading] = useState(false);
  const formDefaults = {
    subscriberName: "",
    subscriberEmail: "",
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: formDefaults });

  const onSubmit = async (formData) => {
    setLoading(true);
    console.log(formData);
    const response = await EmailApi.post("/subscribe", {
      email: formData.subscriberEmail,
      name: formData.subscriberName,
      databaseId: process.env.REACT_APP_SUBSCRIBER_DATABASE,
    });
    if (response.data.state === "success") {
      toast.success("Look out! Updates will be coming your way soon");
      reset(undefined, { keepDefaultValues: true, keepValues: false });
    }
    setLoading(false);
  };
  return (
    <footer id="footer" className="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <h4>Subscribe to our Newsletter</h4>
              <p>Stay updated with the latest news from NKRC.</p>
            </div>
            <div className="col-lg-6">
              <form
                className="row g-2 justify-content-center"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="col-md-6">
                  <label htmlFor="subscriberName" className="visually-hidden">
                    Full Name
                  </label>
                  <input
                    {...register("subscriberName", {
                      required: {
                        value: true,
                        message: "What will we call you?",
                      },
                    })}
                    className="form-control rounded"
                    type="text"
                    placeholder="Service Ninja"
                  />
                  {errors.subscriberName && (
                    <span className="text-small error-text">
                      {errors.subscriberName.message}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="subscriberEmail" className="visually-hidden">
                    Email
                  </label>
                  <input
                    {...register("subscriberEmail", {
                      required: {
                        value: true,
                        message: "How will we contact you?",
                      },
                    })}
                    className="form-control rounded"
                    type="email"
                    placeholder="serviceninja@nkrcja.com"
                  />
                  {errors.subscriberEmail && (
                    <span className="text-small error-text">
                      {errors.subscriberEmail.message}
                    </span>
                  )}
                </div>
                <div className="col-auto">
                  {loading ? (
                    <ClipLoader color="#4154f1" loading className="mb-3 mt-3" />
                  ) : (
                    <PrimaryButton
                      type="submit"
                      title="Subscribe"
                      className="mb-3 btn-sm mt-3"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="/" className="logo d-flex align-items-center">
                <img src="assets/img/logos/rotaract.png" alt="" />
                <img src="assets/img/logos/logo.png" alt="" />
                <img src="assets/img/logos/service-ninja-solo.png" alt="" />
                {/* <span>NKRC</span>  */}
              </a>
              <p>
                Become one of Rotary's people of action or explore the many
                opportunities we have for anyone — whatever your age or interest
                — who wants to improve lives in communities near and far.
              </p>
              <SocialMedia />
            </div>

            <div className="col-lg-4 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <svg
                    className="w-3 h-3 text-gray-800 mr-1"
                    fill="currentColor"
                    stroke="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                  <a href="https://www.rotary.org/en">Rotary.org</a>
                </li>
                <li>
                  <svg
                    className="w-3 h-3 text-gray-800 mr-1"
                    fill="currentColor"
                    stroke="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                  <a href="http://rotaract7020.org/" className="hover:opacity-75">Rotaract 7020</a>
                </li>
                <li>
                  <svg
                    className="w-3 h-3 text-gray-800 mr-1"
                    fill="currentColor"
                    stroke="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                  <a href="https://7020.org/" className="hover:opacity-75">Rotary 7020</a>
                </li>
                <li>
                  <svg
                    className="w-3 h-3 text-gray-800 mr-1"
                    fill="currentColor"
                    stroke="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                  <a href="http://rotaract7020.org/district-conference/" className="hover:opacity-75">
                    District Conference
                  </a>
                </li>
                <li>
                  <svg
                    className="w-3 h-3 text-gray-800 mr-1"
                    fill="currentColor"
                    stroke="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                  </svg>
                  <a href="https://my-cms.rotary.org/en/document/rotaract-handbook" className="hover:opacity-75">
                    Rotaract Handbook
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                c/o Rotaract Club of New Kingston
                <br />
                Chancellor Insurance Agency
                <br />
                14 Dominica Dr, Kingston 5
                <br />
                Kingston, Jamaica
                <br />
                <br />
                <strong>Email: newkingstonrotaract@gmail.com</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Rotaract Club of New Kingston</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;
