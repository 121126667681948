import "./intro.css";
import PrimaryButton from "../../../../components/button/button";

function IntroSection() {
  return (
    // <div>This is the hero</div>
    <section id="hero" className="hero d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <h1>People of Action</h1>
            <h2>
              Join the global movement of young leaders who are developing
              innovative solutions to the world's most pressing challenges.
            </h2>

            <div className="mb-5">
              <div className="text-center text-lg-start">
                  <PrimaryButton title="Get Involved" icon="ri-arrow-right-line" link="/register" className="mt-5"/>
              </div>
            </div>

            <p className="mt-5">
              We meet every 1st and 3rd Thursday of each month at the Chancellor
              Insurance Agency Building in New Kingston at 6:30 p.m.
            </p>
          </div>
          <div className="col-lg-6 hero-img">
            <img
              src="assets/img/hero-img-2.jpeg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
