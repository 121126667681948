const focuses = [
    // icons received from https://remixicon.com/
    {
        icon: "ri-virus-line ", 
        color: "orange", 
        title: "Fighting Disease"
    },
    {
        icon: "ri-hearts-line", 
        color: "blue", 
        title: "Promoting Peace"
    },
    {
        icon: "ri-contrast-drop-2-line", 
        color: "dark-blue", 
        title: "Providing clean water, sanitation, and hygiene"
    },
    {
        icon: "ri-hand-heart-line", 
        color: "red", 
        title: "Saving mothers and children"
    },
    {
        icon: "ri-book-open-line ", 
        color: "purple", 
        title: "Supporting education"
    },
    {
        icon: "ri-bank-line", 
        color: "pink", 
        title: "Growing local economies"
    },
    {
        icon: "ri-seedling-line ", 
        color: "green", 
        title: "Protecting the environment"
    },
]

export default focuses;