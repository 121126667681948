import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
// import { CalendarApi } from "../../service/api";
import CalendarCard from "./component";

import "./calendar.css";
import { SocialMedia } from "../../components/social";

function EventCalendar() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const getEvents = async () => {
    setLoading(true);
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(1)
      }, 1000);
    })
    // console.log(process.env.REACT_APP_CALENDAR_ID);
    // const response = await CalendarApi.post("/events", {
    //   calendarid: process.env.REACT_APP_CALENDAR_ID,
    // });
    // const data = response.data.data;
    setEvents([]);
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, []);
  return (
    <section id="calendar" className="calendar d-flex">
      <div className="container mt-5 align-items-center d-flex flex-column">
        {loading || events.length > 0 ? (
          <h1 className="mb-5">UPCOMING EVENTS</h1>
        ) : (
          <></>
        )}
        {loading && (
          <div className="flex flex-1 items-center justify-center">
            <ClipLoader loading={true} />
          </div>
        )}
        {!loading && events.length === 0 && (
          <div className="flex flex-1 flex-col items-center justify-center">
            <p className="mb-4 font-medium">
              No upcoming events. Follow us on social media to stay updated.
            </p>
            <SocialMedia className="flex flex-row gap-4" size="w-10 h-10" />
          </div>
        )}
        <div className="calendar-cards">
          {events.map((item, inc) => {
            return (
              <CalendarCard
                title={item.summary ?? "No title"}
                body={item.description ?? "No description available."}
                start={item.start?.dateTime || item.start?.date}
                end={item.end?.dateTime}
                location={item.location}
                key={inc}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default EventCalendar;
