function FocusCard(props) {
  return (
    <div className="col-lg-3 col-md-6">
      <div className={`service-box ${props.color}`}>
        <i className={`${props.icon} icon`}></i>
        <h3>{props.title}</h3>
      </div>
    </div>
  );
}

export default FocusCard;