//assets/img/pp/

export const presidentData = [
  {
    year: "2004-2005",
    name: "Marsha Smikle",
    photo: "/assets/img/pp/Marsha_Smikle.png",
  },
  {
    year: "2005-2006",
    name: "Jessica Lewis",
    photo: "/assets/img/pp/Jessica_Lewis.png",
  },
  {
    year: "2006-2007",
    name: "Camille Brady",
    photo: "/assets/img/pp/default.jpeg",
  },
  {
    year: "2007-2008",
    name: "Rochelle Holness",
    photo: "/assets/img/pp/default.jpeg",
  },
  {
    year: "2008-2009",
    name: "Julie Ramchandani",
    photo: "/assets/img/pp/Julie_Ramchandani.png",
  },
  {
    year: "2009-2010",
    name: "Neville Graham",
    photo: "/assets/img/pp/Neville_Graham.png",
  },
  {
    year: "2010-2011",
    name: "Gareth Burrowes",
    photo: "/assets/img/pp/Gareth_Burrowes.png",
  },
  {
    year: "2011-2012",
    name: "Suresh Martin",
    photo: "/assets/img/pp/Suresh_Martin.png",
  },
  {
    year: "2012-2013",
    name: "Travis Graham",
    photo: "/assets/img/pp/Travis_Graham.png",
  },
  {
    year: "2013-2014",
    name: "Dane Anderson",
    photo: "/assets/img/pp/Dane_Anderson.png",
  },
  {
    year: "2014-2015",
    name: "Ryan McKenzie",
    photo: "/assets/img/pp/Ryan_McKenzie.png",
  },
  {
    year: "2015-2016",
    name: "Terence Smith",
    photo: "/assets/img/pp/Terence_Smith.png",
  },
  {
    year: "2016-2017",
    name: "Kimberley Lindo",
    photo: "/assets/img/pp/Kimberley_Lindo.png",
  },
  {
    year: "2017-2018",
    name: "Diego Thomas",
    photo: "/assets/img/pp/Diego_Thomas.png",
  },
  {
    year: "2018-2019",
    name: "Vanessa Williams",
    photo: "/assets/img/pp/Vanessa_Williams.png",
  },
  {
    year: "2019-2020",
    name: "Alex Robinson",
    photo: "/assets/img/pp/Alex_Robinson.png",
  },
  {
    year: "2020-2021",
    name: "Aniska Smith",
    photo: "/assets/img/pp/Aniska_Smith.png",
  },
  {
    year: "2021-2022",
    name: "Shanice Salmon",
    photo: "/assets/img/pp/Shanice_Salmon.png",
  },
  {
    year: "2022-2023",
    name: "Horace Collins",
    photo: "/assets/img/pp/Horace_Collins.png",
  },
  {
    year: "2023-2024",
    name: "Milton G. Edwards",
    photo: "/assets/img/pp/Milton_Edwards.jpeg",
  },
];
