import "./contact.css";
import { useForm } from "react-hook-form";
import { useState } from "react";
import PrimaryButton from "../../../../components/button/button";
import ClipLoader from "react-spinners/ClipLoader";
import { EmailApi } from "../../../../service/api";
import { toast } from "react-hot-toast";

function ContactUs() {
  const formDefaults = {
    contactName: "",
    email: "",
    subject: "Hey, NKRC!",
    message: "",
  };
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: formDefaults });

  const onSubmit = async (formData) => {
    setLoading(true);
    const emailData = {
      name: formData.contactName,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };
    try {
      const response = await EmailApi.post("/contact", emailData);
      if (response.data.state === "success") {
        toast.success(
          `Thank you for contacting us, ${emailData.name}! We will be with you as soon as possible.`
        );
        reset(undefined, { keepDefaultValues: true, keepValues: false });
      } else {
        toast.error(
          "Hmm... We had some issues processing your message. Please try again later."
        );
      }
    } catch (e) {
      toast.error("Unknown error occured. Please try again later.");
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <header className="section-header">
          <h2>GET IN TOUCH</h2>
          <p>Contact Us</p>
        </header>

        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>
                    c/o Rotaract Club of New Kingston
                    <br />
                    Chancellor Insurance Agency
                    <br />
                    14 Dominica Dr, Kingston 5
                    <br />
                    Kingston, Jamaica
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>
                    newkingstonrotaract@gmail.com
                    <br />
                    pr.nkrc@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="email-contact-form"
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    {...register("contactName", {
                      required: {
                        value: true,
                        message: "What will we call you?",
                      },
                    })}
                    placeholder="Name: Service Ninja"
                    className="form-control rounded"
                  />
                  {errors.contactName && (
                    <span className="text-small error-text">
                      {errors.contactName.message}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <input
                    {...register("email", {
                      required: {
                        value: true,
                        message: "How can we get in touch with you?",
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
                        message: "Your email may be invalid.",
                      },
                    })}
                    placeholder="hello@nkrc.com"
                    className="form-control rounded"
                  />
                  {errors.email && (
                    <span className="text-small error-text">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <input
                    {...register("subject", {
                      required: {
                        value: true,
                        message: "Oh No! Did you forgot the subject?",
                      },
                    })}
                    placeholder="Hello there"
                    className="form-control rounded"
                  />
                  {errors.subject && (
                    <span className="text-small error-text">
                      {errors.subject.message}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <textarea
                    {...register("message", {
                      required: {
                        value: true,
                        message: "Don't you want to leave us a message?",
                      },
                    })}
                    className="form-control rounded"
                    rows="6"
                    placeholder="Write your message here. Hopefully it's something nice."
                  ></textarea>
                  {errors.message && (
                    <span className="text-small error-text">
                      {errors.message.message}
                    </span>
                  )}
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                  {loading ? (
                    <ClipLoader color="#4154f1" loading />
                  ) : (
                    <PrimaryButton type="submit" title="Send Message" />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
