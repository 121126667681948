import "./focus.css";
import focuses from "./data";
import FocusCard from "./component";

export default function AreasOfFocus() {
  return (
    <section id="services" className="services">
      <div className="container">
        <header className="section-header">
          <h3>Rotary Areas of Focus</h3>
          <p>
            Rotary is dedicated to causes that build international
            relationships, improve lives, and create a better world to support
            our peace efforts and end polio forever.
          </p>
        </header>

        <div className="row gy-4 justify-content-center">
          {focuses.map((item, inc) => {
            return <FocusCard {...item} key={inc}/>;
          })}
        </div>
      </div>
    </section>
  );
}
