const axios = require("axios").default;

const timeout = 10000

export const API = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_URI,
  timeout: timeout,
});

export const NotionApi = axios.create({
  baseURL: `${process.env.REACT_APP_CLOUD_URI}/notion-api`,
  timeout: timeout,
});
export const EmailApi = axios.create({
  baseURL: `${process.env.REACT_APP_CLOUD_URI}/email-api`,
  timeout: timeout,
});
export const CalendarApi = axios.create({
  baseURL: `${process.env.REACT_APP_CLOUD_URI}/calendar-api`,
  timeout: timeout,
});
