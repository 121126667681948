import Button from "react-bootstrap/Button";
import "./button.css";

function CustomButton(props) {
  return (
    <Button
      href={props.link}
      variant={props.variant}
      className={`custom-button d-inline-flex align-items-center justify-content-center align-self-center${props.className}`}
      target={props.target}
      rel={props.rel}
      type={props.type}
    >
      {props.icon !== undefined && props.iconLocation === "start" ? <i className={`${props.icon} ${props.iconLocation}`}></i> : <></>}
      <span>{props.title}</span>
      {props.icon !== undefined && props.iconLocation === "end" ? <i className={`${props.icon} ${props.iconLocation}`}></i> : <></>}
    </Button>
  );
}


function PrimaryButton(props) {
  const className = ` custom-primary-button${' ' + props.className ?? ''}`;
  return (
    <CustomButton
      className={className}
      variant="primary"
      link={props.link}
      title={props.title}
      icon={props.icon}
      target={props.target}
      rel={props.rel}
      type={props.type}
      iconLocation={props.iconLocation ?? "end"}
    />
  );
}

export default PrimaryButton;
