import { useEffect } from "react";
import team from "./data";
import ExecutiveMemberCard from "./component";
import "./team.css";
// import { NotionApi } from "../../../../service/api";

function BoardMembers() {
  // const [executives, setExecutives] = useState()
  const executives = [
    {
      name: "Milton Edwards",
      image: "/assets/img/team/president.jpeg",
      position: "President",
      order: 1,
    },
    {
      name: "Horace Collins",
      image: "/assets/img/team/ipp.jpg",
      position: "Immediate Past President",
      order: 2,
    },
    {
      name: "Ashley-Marie Auld",
      image: "/assets/img/team/vp.jpeg",
      position: "Vice President",
      order: 3,
    },
    {
      name: "Shanice Salmon",
      image: "/assets/img/team/secretary.jpeg",
      position: "Secretary",
      order: 4,
    },
    {
      name: "Carrie Perrier",
      image: "/assets/img/team/treasurer.jpeg",
      position: "Treasurer",
      order: 5,
    },
    {
      name: "Carissa Davis",
      image: "/assets/img/team/saa.jpeg",
      position: "Sergeant at Arms",
      order: 6,
    },
  ];
  useEffect(() => {
    // async function fetchData() {
    //   // const response = await NotionApi.post("/database", {
    //   //   databaseId: process.env.REACT_APP_EXECUTIVE_DATABASE,
    //   //   data_type: "executives"
    //   // });
    //   const data = response.data;
    //   console.log(JSON.stringify(data.data));
    //   if(data.state === "success") {
    //     setExecutives(data.data);
    //   }
    // }
    // fetchData();
  }, []);
  return (
    <section id="team" className="team">
      <div className="container">
        <header className="section-header">
          <h2>NKRC Team for 2022-2023</h2>
          <p>Meet the Executives</p>
        </header>

        <div className="row gy-4 d-flex justify-content-center">
          {(executives.length === 0 ? team : executives).map((item, inc) => {
            return <ExecutiveMemberCard {...item} key={inc} />;
          })}
        </div>
      </div>
    </section>
  );
}

export default BoardMembers;
