function ExecutiveMemberCard(props) {
  var uri = "";
  try {
    const url = new URL(props.image);
    if (url.protocol === "http:" || url.protocol === "https:") {
      uri = props.image;
    } else {
      uri = "assets/img/team/default.jpeg";
    }
  } catch (_) {
    uri = `${props.image ?? "assets/img/team/default.jpeg"}`;
  }
  return (
    <div className="col-lg-3 col-md-6 d-flex align-items-stretch cursor-pointer">
      <div className="member">
        <div className="member-img">
          <img
            src={uri}
            className="img-fluid"
            alt={`${props.position} of NKRC`}
          />
        </div>
        <div className="member-info">
          <h4>{props.name}</h4>
          <span>{props.position}</span>
        </div>
      </div>
    </div>
  );
}

export default ExecutiveMemberCard;
