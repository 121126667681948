import PrimaryButton from "../../components/button/button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Keyboard, Autoplay } from "swiper";
import { useForm } from "react-hook-form";

import "swiper/css";
import "swiper/css/effect-fade";

import "./register.css";
import { useState } from "react";

function RegistrationForm() {
  const formDefaults = {};
  const [data, setData] = useState({});
  const [index, setIndex] = useState(0);
  const keyPress = (swiper, keyCode) => {
    console.log(swiper);
    console.log(keyCode);
  };
  const onSubmit = async (formData) => {
    alert("Submitting form");
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({ defaultValues: formDefaults });

  const swiperChange = (swiper) => {
    setIndex(swiper.activeIndex);
    setData(getValues());
    console.log(getValues());
    swiper.navigation.init();
    swiper.navigation.update();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="swiper-holder bg-light">
        <Swiper
          onSlideChange={swiperChange}
          onKeyPress={keyPress}
          pagination={{ type: "progressbar" }}
          navigation={{
            nextEl: ".swiper-button-next-custom",
            prevEl: ".swiper-button-previous-custom",
          }}
          // onInit={(swiper)=> {

          // }}
          keyboard={{ enabled: true }}
          modules={[Pagination, Navigation, Keyboard, Autoplay]}
          className="membership-form"
          allowTouchMove={false}
        >
          <SwiperSlide>
            <div className="p-5">
              <div className="row">
                <div className="col-6 d-flex align-items-center flex-column justify-content-center">
                  <img
                    src="assets/img/logos/service-ninja-solo.png"
                    alt="Service Ninja Logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 p-5 d-flex flex-column justify-content-between">
                  <div>
                    <h2 className="mb-5">NKRC Membership Application Form</h2>
                    <p>
                      The core principle of Rotary is "Service Above Self,"
                      which calls on Rotaractors and Rotarians to put service to
                      their communities above all else. Through this, the core
                      principle of "Fellowship Through Service" was formed where
                      Rotaract clubs would focus on building friendships among
                      their club members while they endeavour to serve their
                      community. The 4-way test covers both principles,
                      challenging both Rotaractors and Rotarians to live by them
                      throughout their everyday lives.
                    </p>
                  </div>
                  {index === 0 ? (
                    <PrimaryButton
                      title="GET STARTED"
                      className="swiper-button-next-custom"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="d-flex flex-column w-50">
              <div className="register-form-group">
                <input
                  required={true}
                  type="text"
                  id="registerFormFirstName"
                  {...register("registerFormFirstName", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                  className="form-control"
                />
                <label htmlFor="registerFormFirstName">
                  Let's start off with your first name *
                </label>
                {errors.registerFormFirstName && (
                  <span className="text-small error-text">
                    {errors.registerFormFirstName.message}
                  </span>
                )}
              </div>
              <div className="d-flex d-grid gap-2 mt-3 col-8 justify-content-around align-self-center">
                {/* <PrimaryButton
                  title="Previous"
                  className="col-4 btn-sm swiper-button-previous-custom"
                  icon="ri-arrow-left-s-line"
                  iconLocation="start"
                /> */}
                {index === 1 ? (
                  <PrimaryButton
                    title="Next"
                    className="col-4 btn-sm ml-2 swiper-button-next-custom"
                    icon="ri-arrow-right-s-line"
                    type="button"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="d-flex flex-column w-50">
              {data.registerFormFirstName === undefined ||
              data.registerFormFirstName === "" ? (
                <h1>Oh no! You forgot to tell us your first name.</h1>
              ) : (
                <div className="register-form-group">
                  <input
                    required={true}
                    type="text"
                    id="registerFormLastName"
                    {...register("registerFormLastName", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                    className="form-control"
                  />
                  <label htmlFor="registerFormLastName">{`Nice name, ${data.registerFormFirstName}! What is your last name?`}</label>
                  {errors.registerFormLastName && (
                    <span className="text-small error-text">
                      {errors.registerFormLastName.message}
                    </span>
                  )}
                </div>
              )}
              {index === 2 ? (
                <div className="d-flex d-grid gap-2 mt-3 col-8 justify-content-around align-self-center">
                  <PrimaryButton
                    title="Previous"
                    className="col-4 btn-sm swiper-button-previous-custom"
                    icon="ri-arrow-left-s-line"
                    iconLocation="start"
                    type="button"
                  />

                  {data.registerFormFirstName === undefined ||
                  data.registerFormFirstName === "" ? (
                    <></>
                  ) : (
                    <PrimaryButton
                      title="Next"
                      className="hidden col-4 btn-sm ml-2 swiper-button-next-custom"
                      icon="ri-arrow-right-s-line"
                      type="button"
                    />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <PrimaryButton
              title="Next"
              className="col-4 btn-sm ml-2"
              icon="ri-arrow-right-s-line"
            />
          </SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
          <SwiperSlide>Slide 4</SwiperSlide>
          <SwiperSlide>Slide 5</SwiperSlide>
          <SwiperSlide>Slide 6</SwiperSlide>
          <SwiperSlide>Slide 7</SwiperSlide>
          <SwiperSlide>Slide 8</SwiperSlide>
          <SwiperSlide>Slide 9</SwiperSlide>
        </Swiper>
      </div>
    </form>
  );
}
export default RegistrationForm;
