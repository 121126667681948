import "./about.css";
import PrimaryButton from "../../../../components/button/button"
import DataCard from "./component";

// https://dribbble.com/shots/15673374-Nexon-Finance-Management-Transactions

function AboutSection() {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="content">
              <h3>Who We Are</h3>
              <h2>Rotaract Club of New Kingston</h2>
              <p>
                The Rotaract Club of New Kingston (NKRC) is a non-profit
                organization that was officially chartered on April 7 in 2004,
                which was signed off by the then President of the Rotary Club of
                New Kingston Dr. Garth Alexander. However, we celebrate our
                charter day on April 1, 2004 as that was the day the first board
                was installed. The club comprises of young professionals over
                the age of 18 who are passionate about service and giving back
                to society and does so in numerous ways. We meet every 1st and
                3rd Thursday of each month at the Chancellor Insurance Agency
                Building in New Kingston at 6:30 p.m.
              </p>
              <p>
                The core principle of Rotary is "Service Above Self," which
                calls on Rotaractors and Rotarians to put service to their
                communities above all else. Through this, the core principle of
                "Fellowship Through Service" was formed where Rotaract clubs
                would focus on building friendships among their club members
                while they endeavour to serve their community. The 4-way test
                covers both principles, challenging both Rotaractors and
                Rotarians to live by them throughout their everyday lives.
              </p>
              <h2>
                Your time, energy, and a passion to improve your world are all
                you need to get involved with Rotary.
              </h2>
              <div className="mt-5 text-center text-lg-start">
                <PrimaryButton
                  link="https://www.rotary.org/en/get-involved/rotaract-clubs#page-content/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Read More"
                  icon="ri-arrow-right-line"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex align-items-center">
            <img src="assets/img/about-img.jpeg" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      <div className="counts" id="counts">
        <div className="container">
          <div className="row gy-4">
            <DataCard
              title="5000+"
              text="Lives Impacted"
              icon="ri-service-fill"
            />
            <DataCard
              title="100+"
              text="Projects Completed"
              icon="ri-honour-fill"
              style={{ color: "#ee6c20" }}
            />
            <DataCard
              title="100+"
              text="Partnerships"
              icon="ri-team-fill"
              style={{ color: "#15be56" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
