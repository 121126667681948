
function DataCard(props) {
    return (
      <div className="col-lg-4 col-md-6">
        <div className="count-box justify-content-center">
          <i className={`${props.icon}`} style={props.style}></i>
          <div>
            <span>{props.title}</span>
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    );
  }
  
  
  export default DataCard;