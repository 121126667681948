import "./App.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import NKRCApp from "./pages/home/nkrc";
import EventCalendar from "./pages/calendar/calendar"
import RegistrationForm from "./pages/register/register";
import Honours from "./pages/honours/honours";
import Presidents from "./pages/presidents/president";
import NotableProjects from "./pages/projects/projects";
function App() {
  return (
    <>
      <div>
        <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
      </div>
      <Header />

      <Routes>
        <Route path="/" element={<NKRCApp />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/events" element={<EventCalendar />} />
        <Route path="/achievements" element={<Honours />} />
        <Route path="/presidents" element={<Presidents />} />
        <Route path="/projects" element={<NotableProjects />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
