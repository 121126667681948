import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../button/button";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { NotionApi } from "../../../service/api";
import { toast } from "react-hot-toast";
import { ClipLoader } from "react-spinners";

function ShirtForm() {
  const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [total, setTotal] = useState(0);
  const [loadingShirts, setLoadingShirts] = useState(false);
  const [clubShirts, setClubShirts] = useState([]);
  const [shirtsError, setshirtsError] = useState(undefined);
  const [selectedShirts, setSelectedShirts] = useState([]);
  const [domprocessing, setDomprocessing] = useState(false);
  const formDefaults = {
    shirtContactName: "",
    shirtContactPhone: "",
    shirtContactGender: "",
    shirtContactSize: "",
    shirtContactComment: "",
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: formDefaults });

  useEffect(() => {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    async function fetchData() {
      setLoadingShirts(true);
      const response = await NotionApi.post("/database", {
        databaseId: process.env.REACT_APP_SHIRTS_DATABASE,
        data_type: "shirts",
      });
      const data = response.data;
      if (data.state === "success") {
        setClubShirts(
          data.data.map((shirt) => {
            return {
              key: shirt.shirt,
              label: `${shirt.shirt} (J${formatter.format(shirt.cost)})`,
              cost: shirt.cost,
            };
          })
        );
      }
      setLoadingShirts(false);
    }

    fetchData();
  }, []);

  const handleOnChange = (selected) => {
    setshirtsError(undefined);
    let cost = selected
      .map((item) => {
        return clubShirts.find((e) => e.key === item).cost;
      })
      .reduce((a, b) => a + b, 0);
    setTotal(cost);
    setSelectedShirts(selected);
  };

  const onSubmit = async (formData) => {
    setshirtsError(undefined);
    console.log(formData);
    if (selectedShirts.length === 0) {
      setshirtsError("Please select at least 1");
      console.log("Setting shirts Error");
      return;
    }

    const payload = {
      name: formData.shirtContactName,
      phone: formData.shirtContactPhone,
      gender: formData.shirtContactGender,
      size: formData.shirtContactSize,
      comment: formData.shirtContactComment,
      shirts: selectedShirts,
      total: total,
      databaseId: process.env.REACT_APP_SHIRT_ORDERS_DATABASE,
      fn: "shirts",
      state: "New",
    };

    try {
      setDomprocessing(true);
      const response = await NotionApi.post("/save", payload);
      setDomprocessing(false);
      console.log(response.data);
      if (response.data.state === "success") {
        toast.success(
          "Don't forget to send us a copy of your proof of payment",
          { duration: 10000 }
        );
        reset(undefined, { keepDefaultValues: true, keepValues: false });
      } else {
        toast.error(
          "There was an error processing your request. Please refresh and try again later. "
        );
      }
    } catch (e) {
      toast.error("Unknown error occured. Please try again later.");
      console.log(e);
    }
  };

  return (
    <>
      <h4>Shirt Request</h4>
      {loadingShirts ? (
        <ClipLoader />
      ) : (
        <>
          <span className="text-large">{`Total Cost: J${moneyFormatter.format(
            total
          )}`}</span>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
            <div className="row">
              <div className="col-md-6 form-group">
                <input
                  {...register("shirtContactName", {
                    required: {
                      value: true,
                      message: "Your name is required.",
                    },
                  })}
                  className="form-control"
                  placeholder="Name: Service Ninja"
                />
                {errors.shirtContactName && (
                  <span className="text-small error-text">
                    {errors.shirtContactName.message}
                  </span>
                )}
              </div>
              <div className="col-md-6 form-group">
                <input
                  {...register("shirtContactPhone", {
                    required: {
                      value: true,
                      message: "Just in case we need to contact you.",
                    },
                  })}
                  type="text"
                  className="form-control"
                  placeholder="18760000000"
                />
                {errors.shirtContactPhone && (
                  <span className="text-small error-text">
                    {errors.shirtContactPhone.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <DropdownMultiselect
                  options={clubShirts}
                  name="shirts"
                  className="form-control"
                  handleOnChange={handleOnChange}
                />
                {shirtsError && (
                  <span className="text-small error-text">{shirtsError}</span>
                )}
              </div>
              <div className="col-md-6 form-group">
                <select
                  {...register("shirtContactGender", {
                    required: {
                      value: true,
                      message: "Shirt size varies based on gender.",
                    },
                  })}
                  className="form-control"
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errors.shirtContactGender && (
                  <span className="text-small error-text">
                    {errors.shirtContactGender.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <select
                  {...register("shirtContactSize", {
                    required: {
                      value: true,
                      message: "What size do you wear?",
                    },
                  })}
                  className="form-control"
                >
                  <option value="">Select Size</option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                </select>
                {errors.shirtContactSize && (
                  <span className="text-small error-text">
                    {errors.shirtContactSize.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <textarea
                  {...register("shirtContactComment")}
                  className="form-control"
                  placeholder="Any instructions?"
                ></textarea>
              </div>
            </div>
            {/* <PrimaryButton title="Save" type="submit" /> */}
            {domprocessing ? (
              <ClipLoader color="#4154f1" loading={domprocessing} />
            ) : (
              <PrimaryButton type="submit" title="Save" />
            )}
          </form>
        </>
      )}
    </>
  );
}

export default ShirtForm;
