import { honours } from "./data";
// https://flowbite.com/blocks/marketing/event-schedule/
function Honours() {
    honours.reverse();
  return (
    <div className="p-32">
      <h3 className="text-[#4154f1] text-[14px] font-bold uppercase">
        Our Achievements
      </h3>
      <h2 className="text-[#012970] font-bold text-[24px] uppercase mb-2">Celebrating NKRC</h2>

      <p className="mb-3">
        Welcome to our Rotaract Club's Honours page! Here, we celebrate the
        accomplishments and achievements of our members who have gone above and
        beyond in their dedication to service, leadership, and making a positive
        impact in our community. We are proud to recognize and honor the hard
        work and commitment of our fellow Rotaractors.
      </p>
      <ol class="relative border-l border-gray-200">
        {honours.map((honour) => {
          return (
            <Tile
              year={honour.year}
              date={honour.date}
              associate={honour.associated}
              title={honour.award}
              issuer={honour.issuer}
              description={honour.description}
            />
          );
        })}
      </ol>
    </div>
  );
}

const Tile = ({ date, associate, title, issuer, description, year }) => {
  return (
    <li class="mb-10 ml-4">
      <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3">
        <svg
          aria-hidden="true"
          class="w-3 h-3 text-blue-800 dark:text-blue-300"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </span>
      <time class="mb-1 text-sm font-normal leading-none text-gray-400 ml-1">
        {date}
      </time>
      <div className="flex flex-row space-x-2 mt-2">
        <span class="flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3">
          <img
            class="rounded-full shadow-lg"
            src="assets/img/club-partner.jpeg"
            alt="Rotaract Club Partner Logo"
          />
        </span>
        <p className="mb-2 font-medium text-sm text-gray-700">
          Associated With {associate}
        </p>
      </div>
      <h3 class="text-xl font-bold text-gray-900">
        {year} {title}
      </h3>
      <p className="mb-2 font-normal text-sm text-gray-500">
        Issued by {issuer}
      </p>
      <p class="mb-1 text-base font-normal text-gray-500">{description}</p>
    </li>
  );
};

export default Honours;
