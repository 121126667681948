import React from 'react'

const NotableProjects = () => {
  return (
    <div className='flex flex-1 h-screen items-center justify-center'>
        Notable Projects
    </div>
  )
}

export default NotableProjects
