export const honours = [
  // ALWAYS ADD TO THE BOTTOM OF THE LIST
  {
    year: "2015-2016",
    issuer: "Rotary Inaternational",
    associated: "Rotaract",
    award: "Rotary Presidential Citation for Rotaract Clubs",
    date: "2015/16",
    description: undefined,
  },

  {
    year: "2013-2014",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Rotaractor of the Year",
    date: "May 2014",
    description:
      "This award was awarded to Rtn Donair Wilmouth for his excellent contribution to the club in the 2013-2014 Rotary year",
  },
  {
    year: "2013-2014",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Gold Status Award (DRR Award Guide for Excellence)",
    date: "May 2014",
    description:
      "This status was the second highest status category implemented by Rotaract District 7020 in the 2013-2014 Rotary Year",
  },
  {
    year: "2013-2014",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Year",
    date: "May 2014",
    description: undefined,
  },
  {
    year: "2014-2015",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Quarters 1 & 2",
    date: "Oct 2014",
    description: undefined,
  },
  {
    year: "2014-2015",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Platinum Status Award (DRR Award Guide for Excellence)",
    date: "May 2015",
    description:
      "This status was the highest status category implemented by Rotaract District 7020 in the 2014-2015 Rotary Year",
  },
  {
    year: "2014-2015",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Year",
    date: "Jun 2015",
    description:
      "This status was the highest status category implemented by Rotaract District 7020 in the 2014-2015 Rotary Year",
  },
  {
    year: "2015-2016",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Quarters 1 & 2",
    date: "Oct 2015",
    description: undefined,
  },
  {
    year: "2015-2016",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "Zone 34 Public Image Award",
    date: "May 2016",
    description: undefined,
  },
  {
    year: "2015-2016",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Rotaractor of the Year",
    date: "May 2016",
    description:
      "Dr. Trudy-Ann Johnson received the Rotaractor of the Year Award from Rotaract District 7020 for her excellent contribution to club in the 2015-2016 Rotary Year",
  },
  {
    year: "2015-2016",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "Platinum Status Award (DRR Award Guide for Excellence)",
    date: "Jun 2016",
    description:
      "This status was the highest status category implemented by Rotaract District 7020 in the 2015-2016 Rotary Year",
  },
  {
    year: "2016-2017",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Platinum Status Award (DRR Award Guide for Excellence)",
    date: "May 2017",
    description:
      "This status was the highest status category implemented by Rotaract District 7020 in the 2016-2017 Rotary Year",
  },
  {
    year: "2016-2017",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of Quarters 1 & 2",
    date: "Jun 2017",
    description: undefined,
  },
  {
    year: "2016-2017",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Year",
    date: "Jun 2017",
    description: undefined,
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of Quarters 1 & 2",
    date: "Oct 2017",
    description: undefined,
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "Rotary Zone 34 Public Image Award",
    date: "May 2018",
    description: undefined,
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Outstanding Rotaract Project",
    date: "May 2018",
    description:
      "The District recognized our outstanding achievement for the Back to School Medical submission that was recognized by Rotary International for the ROPA awards",
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Most Hashtags Award",
    date: "May 2018",
    description: "Received the award for using the hashtags on social media",
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Hands Across the District - Certificate of Participation",
    date: "May 2018",
    description: undefined,
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "Rotary International Citation for Rotaract Clubs",
    date: "Jun 2018",
    description: undefined,
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award:
      "Rotaract Outstanding Projects Award (ROPA) Recipient (Winner) for the Canada, Caribbean, and United States Region",
    date: "Jun 2018",
    description:
      "This award was given to us by Rotary International (Canada, Caribbean and United States Region) for our Community Service project- Back to School medical which impacted 101 residents from the Nannyville Community. The project was evaluated by RI staff and Rotaract Committee members on criteria including sustainability, community assessment, promotion, impact within Rotary's areas of focus, involvement of multiple Rotaract clubs, and collaboration with Rotarians and local experts.",
  },
  {
    year: "2017-2018",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Year",
    date: "Jun 2018",
    description: undefined,
  },
  {
    year: "2018-2019",
    issuer: "Government of Jamaica",
    associated: "Jamaica",
    award:
      "Prime Minister's National Youth Award for Excellence (PMNYAE) - Nominee",
    date: "Nov 2018",
    description:
      "We were nominated for the PMNYAE for the work done in the community for the 2017-2018 Rotary year. The nominee was made known in the 2018-2019 Rotary year.",
  },
  {
    year: "2018-2019",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "Rotary Zone 34 Public Image Award (Platinum Citation)",
    date: "Apr 2019",
    description: undefined,
  },
  {
    year: "2018-2019",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Platinum Status Award (DRR Award Guide for Excellence)",
    date: "Jun 2019",
    description:
      "This status was the highest status category implemented by Rotaract District 7020 in the 2018-2019 Rotary Year",
  },
  {
    year: "2018-2019",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 District Rotaract Project- Second Place Awardee",
    date: "Jun 2019",
    description: undefined,
  },
  {
    year: "2018-2019",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Year",
    date: "Jun 2019",
    description: undefined,
  },
  {
    year: "2019-2020",
    issuer: "Rotary International",
    associated: "Rotaract",
    award:
      "Rotaract Outstanding Projects Award (ROPA) (Nominee) for the Canada, Caribbean, and United States Region",
    date: "Mar 2020",
    description:
      "Our Back to School Medical held in the Nannyville Community was nominated for the Rotaract Outstanding Projects Award in the 2019-2020 Rotary year for the Canada, Caribbean and United States Region",
  },
  {
    year: "2019-2020",
    issuer: "Rotary International",
    associated: "Rotaract",
    award: "Rotary Zone 34 Public Image Award (Platinum Citation)",
    date: "Jun 2020",
    description: undefined,
  },
  {
    year: "2019-2020",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Rotaractor of the Year (Second Runner up)",
    date: "Jun 2020",
    description:
      "Rtr Keon Price came third in the bid for RD7020 Rotaractor of the Year for the 2019-2020 Rotary Year",
  },
  {
    year: "2019-2020",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award:
      "D7020 Outstanding District Rotaract International Services Project Award (First Place Awardee)",
    date: "Jun 2020",
    description:
      "Received the first place award for ART-TIVITY District Rotaract International Service Project- This project was executed with the children from Lister Mair Gilby High School for the Deaf",
  },
  {
    year: "2019-2020",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Distinguished Club of the Year",
    date: "Jun 2020",
    description: undefined,
  },
  {
    year: "2019-2020",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Diamond Status Award (DRR Award Guide for Excellence)",
    date: "Jun 2020",
    description:
      "This status was the highest status category implemented by Rotaract District 7020 in the 2019-2020 Rotary Year",
  },
  {
    year: "2020-2021",
    issuer: "Rotary International",
    associated: "Rotaract",
    award: "Rotary Zone 34 Public Image Award",
    date: "Jun 2020",
    description: undefined,
  },
  {
    year: "2020-2021",
    issuer: "Rotary District 7020",
    associated: "Rotaract",
    award: "Rotary D7020 Partnership Award",
    date: "Jun 2021",
    description:
      "Rotary District 7020, under the leadership of PDG Charles Sealy, recognized us for partnering with several clubs within Jamaica, across District 7020, across District 7030 and outside of District 7020 and 7030",
  },
  {
    year: "2020-2021",
    issuer: "Rotary District 7020",
    associated: "Rotaract",
    award: "Rotary D7020 Certificate of Distinction",
    date: "Jun 2021",
    description:
      "Rotary District 7020, under the leadership of PDG Charles Sealy, recognized us for successfully completing the Rotary award guide for excellence for Rotaract Clubs in the 2020-2021 Rotary year",
  },
  {
    year: "2020-2021",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "Platinum Status Award (DRR Award Guide for Excellence)",
    date: "Jun 2021",
    description:
      "This status was the highest status category implemented by Rotaract District 7020 in the 2020-2021 Rotary Year",
  },
  {
    year: "2020-2021",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 District Rotaract Project - Second Place Awardee",
    date: "Jun 2021",
    description:
      "Received second place for the District Rotaract Project - Little Hands Little Hearts",
  },
  {
    year: "2020-2021",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Club of the Year",
    date: "Jun 2021",
    description: undefined,
  },
  {
    year: "2021-2022",
    issuer: "Rotaract District 7020",
    associated: "Rotaract",
    award: "D7020 Platinum Award (DRR Award Guide for Excellence)",
    date: "Jun 2022",
    description:
      "Second Highest Status available from the District in the 2021-2022 Rotary Year",
  },
];

