import AreasOfFocus from "./components/focus/focus";
import AboutSection from "./components/about/about";
import IntroSection from "./components/intro/intro";
import BoardMembers from "./components/team/team";
import ContactUs from "./components/contact/contact";


function NKRCApp() {
  return (
    <>
      <IntroSection />
      <AboutSection />
      <AreasOfFocus />
      <BoardMembers />
      <ContactUs />
    </>
  );
}

export default NKRCApp;